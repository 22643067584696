
import { defineComponent, ref } from 'vue'
import usePackageFinanceDetail from "@/hooks/checking/spotChecking/checkingPreparation/usePackageFinanceDetail";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-420
import TableWrapper from "@/components/TableWrapper.vue";
import { useStore } from "vuex";
import commentModal from './commentModal.vue';
export default defineComponent({
  components: { commentModal },
  name: 'payoutPreparation',
  props: {},
  setup() {
    const store = useStore()
    const { state, commit } = useStore()
    const {
      searchParamsMBCL, doReset,
      columnsMBCLData, tableDataMBCL, doSearch, pageChange, sizeChange, pageSize, pageNumber, total,
      payoutRoundClick, commentVisible, commentData, commentClick
    } = usePackageFinanceDetail()
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }
    return {
      store,
      searchParamsMBCL,
      doSearch,
      doReset,
      tableWidth,
      tableHeight,
      columnsMBCLData,
      tableDataMBCL,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      payoutRoundClick,
      commentVisible,
      commentData,
      commentClick
    }
  }
})
