import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import useThousands from "@/utils/payment/useThousands";
import { toThousands } from '@/utils/payment/numberFormat'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {fetchCheckFinanceProgramList} from "@/API/checking/spotChecking";

type SearchParams = {
    id: string;
    packgeCode: string;
    programNo: string;
    claimNo: string;
    timeLine: string;
    priority: string;
    assginee: string;
}
export interface TableDataMBCL {
    programId: string;
    payoutCode: string;
    programCode: string;
    programPeriodFrom: string;
    to: string;
    vinNo: string;
    spotCheckStatus: string;
    sampleCheckStatus: string;
    controllingCheckStatus: string;
}

const useVehicleNameSet = () => {
    const { state, commit } = useStore()
    const router = useRouter()
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsFinanceData = computed(() => {
        return [
            { title: "Payout Code", dataIndex: "programCode" },
            { title: "Program Code", dataIndex: "programCode" },
            { title: "Program Name", dataIndex: "programName"},
            { title: "Program Period From", dataIndex: "programPeriodFrom",slots: { customRender: 'programPeriodFrom' }},
            { title: "To", dataIndex: "to",slots: { customRender: 'to' }},
            { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: "right" },
            { title: "Logic Checked", dataIndex: "spotCheckStatus",slots: { customRender: 'spotCheckStatus' } },
            { title: "Sample Checked", dataIndex: "sampleCheckStatus",slots: { customRender: 'sampleCheckStatus' }},
            { title: "Controlling Checked", dataIndex: "controllingCheckStatus",slots: { customRender: 'controllingCheckStatus' }},
            { title: "Comment", dataIndex: "comment",slots: { customRender: 'comment' }}
        ]
    })
    const searchParamsMBCL = reactive({
        id: '',
        payoutCode: '',
        programNo: '',
        VinNo: '',
        timeLine: '',
        priority: '',
        assginee: ''
    })
    const tableData = ref<TableDataMBCL[]>()
    const tableDataMBCL = ref<TableDataMBCL[]>()
    const getTableData = () => {
        const params = {
            payoutCodeId: searchParamsMBCL.id,
            payoutCode: searchParamsMBCL.payoutCode,
            pageNumber: pageNumber.value,
            pageSize: pageSize.value
        }
        API.fetchCheckFinanceProgramList(params).then((data: any) => {
            console.log('金融返回结果=================',data.content[0].spotCheckPayoutCodeRespVOList)
            searchParamsMBCL.programNo = toThousands(data.content[0].programNo)
            searchParamsMBCL.VinNo = toThousands(data.content[0].totalVinNo)
            searchParamsMBCL.priority = data.content[0].priority
            searchParamsMBCL.assginee = data.content[0].assignee
            const beginTime = data.content[0].timeLineBegin ? data.content[0].timeLineBegin.toString().substr(0, 10) : ''
            const endTime = data.content[0].timeLineEnd ? data.content[0].timeLineEnd.toString().substr(0, 10) : ''
            searchParamsMBCL.timeLine = beginTime && endTime ?  beginTime + ' - ' + endTime : ''
            tableDataMBCL.value = data.content[0].spotCheckPayoutCodeRespVOList
            total.value = data.totalElements
        })
    }
    const doSearch = () => {
        console.log('search按钮')
        pageNumber.value = 1
        getTableData()
    }
    const doReset = () => {
        tableData.value = []
        getTableData()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const payoutRoundClick = (record: any) => {
        const info = {
            payoutRoundId: record.payoutRoundId,
            payoutRound: record.payoutRound,
            status: record.status
        }
        console.log(info)
        commit('spotChecking/updatePayoutRoundData', info)
        router.push({ path: '/spotChecking/pendingApproval/payoutRound' })
    }
    onMounted(() => {
        searchParamsMBCL.id = state.spotChecking.packageFinanceData.id + ''
        searchParamsMBCL.payoutCode = state.spotChecking.packageFinanceData.payoutCode
        doSearch()
    })
    const columnsMBCLData = computed(() => {
        return [
            { title: "payout Code", dataIndex: "payoutCode" },
            { title: "Program Code", dataIndex: "programCode"},
            { title: "Program Name", dataIndex: "programName"},
            { title: "Program Period From", dataIndex: "programPeriodFrom",slots: { customRender: 'programPeriodFrom' }},
            { title: "To", dataIndex: "to",slots: { customRender: 'to' }},
            { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: "right" },
            { title: "Spot Check", dataIndex: "spotCheckStatus" },
            { title: "Sample Check", dataIndex: "sampleCheckStatus", align: 'center'},
            { title: "Controlling Check", dataIndex: "controllingCheckStatus"},
            { title: "Comment", dataIndex: "comment",slots: { customRender: 'comment' }}
        ]
    })

    //#region comment
    const commentVisible = ref(false)
    const commentData = ref()
    const commentClick = (record: TableDataMBCL) => {
        const params = {
            payoutCodeId: searchParamsMBCL.id,
            programId: record.programId
        }
        API.fetchCheckFinanceCommentList(params).then(data => {
            commentData.value = data
            commentVisible.value = true
        })
    }
    //#endregion

    return {
        searchParamsMBCL,
        columnsFinanceData,
        columnsMBCLData,
        tableData,
        tableDataMBCL,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        payoutRoundClick,
        commentVisible,
        commentData,
        commentClick
    }
}
export default useVehicleNameSet